<template>
  <div class="full-width" v-if="params != false">
    <div class="p-5 w-100 home-main">
      <b-row>
        <b-col md="6" class="text-center m-t-19">
          <p class="text-primary titre">Koupat App, votre coutier 2.0</p>
          <p class="sous-titre">Les meilleurs offres en quelque clique !</p>
        </b-col>
        <b-col offset-md="1" md="3" class="m-t-10">
          <vs-button-group>
            <vs-button size="large" @click="lead.famille = false" :active="lead.famille === false">
              <i class="fas fa-2x fa-user text-white"></i>
              <span>&nbsp;&nbsp;Seul(e)</span>
            </vs-button>
            <vs-button size="large" @click="lead.famille = true" :active="lead.famille">
              <i class="fas fa-2x fa-users text-white"></i>
              <span>&nbsp;&nbsp;Famille</span>
            </vs-button>
          </vs-button-group>
          <b-card class="card-search card-shadow">
            <form ref="form_lead">
              <b-row>
                <b-col md="12" class="mb-3">
                  <vs-select state="primary" filter placeholder="Votre regime sociale" v-model="lead.regime_id"  :required="true">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                    <vs-option v-for="(r, i) in params.Regime" :key="i" :label="r.titre" :value="r.id">
                      {{r.titre}}
                    </vs-option>
                  </vs-select>
                </b-col>
                <b-col md="12" class="mb-3">
                  <vs-input type="date" placeholder="Votre date de naissance" v-model="lead.date_naissance" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-birthday-cake text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="12" class="mb-3">
                  <vs-input placeholder="Votre code postal" v-model="lead.cp" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-city text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="12" class="mb-3">
                  <vs-input v-model="lead.nom" placeholder="Votre nom" color="secondary" state="primary" :required="true">
                    <template #icon>
                      <i class='fas fa-user text-primary'></i>
                    </template>
                  </vs-input>
                </b-col>
                <b-col md="12" class="mb-3">
                  <vs-input placeholder="Votre email" v-model="lead.email" :required="true" color="secondary" state="primary">
                    <template #icon>
                      <i class="fas fa-at text-primary"></i>
                    </template>
                  </vs-input>
                </b-col>
              </b-row>
            </form>
            <div class="text-right">
              <vs-button size="large" color="secondary" @click="nouveau()">
                Suivant
              </vs-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>    
  </div>
</template>
<script>
export default {
  name: "Home",
  data: () => ({
    params: false,
    lead:{
      "famille":null,
      "mariee":false,
      "enfant":false,
      "nom": "",
      "prenom": "",
      "email": "",
      "telephone": "",
      "mobile": "",
      "date_naissance": null,
      "date_effet": null,
      "cp": "",
      "regime_id": "",
      "conjoint": {
        "regime_id": "",
        "date_naissance": null
      },
      "enfants": [],
      "mutuelle_id": []
    }
  }),
  methods: {
    nouveau() {
      if(this.$refs.form_lead.reportValidity() === false){
        return false;
      }
      this.$router.push({path:"/nouveau", query:{lead:JSON.stringify(this.lead)}})
    }
  },
  beforeMount() {
    this.$api.ajax('https://api.laf.jg.app/d/params', null, res => {
      if(res.status === true){
        this.params = res.data;
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.m-t-19{
  margin-top: 19vh;
}
.m-t-10{
  margin-top: 10vh;
}
.titre {
  font-size: 40px !important;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
}
@media (max-width: 768px){
  .m-t-10{
    margin-top: 5vh;
  }
  .m-t-19{
    margin-top: 0vh;
  }
}
.sous-titre {
  font-weight: 600;
  font-size: 25px !important;
  color: #fff;
}
.home-main {
  background-image: url("~@/assets/img/bg-h.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // height: 650px;
}
</style>
